
import { as_json, format_date_less_one, number_to_currency } from '@/lib/helpers'
import QuantityControl from '@/components/quantity_control'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: { QuantityControl, LoadingSpinner },

  data: -> {
    qty: 0,
    show: false
    loading: false
    enlarge_image: false
    confirm_removal: false
  },

  methods: {
    add_to_cart: ->
      @loading = true
      if !!@$store.state.cart.proxy_order_id
        @$store.dispatch('orders/add_item', { offer: as_json(@offer), qty: as_json(@qty) })
        @dismiss()
        @$router.push("/orders/#{@$store.state.cart.proxy_order_id}")
        @$store.commit('cart/stop_order_proxy')
      else
        @$store.dispatch('cart/add_item', { offer: as_json(@offer), qty: as_json(@qty) })
          .then =>
            @track_add_to_cart()
            @dismiss()

    remove_item_from_cart: ->
      @$store.dispatch("cart/remove_item", @offer.id)
      @dismiss()

    dismiss: ->
      @$store.commit('offers/set_current', null)
      @qty = -1
      @loading = false
      @confirm_removal = false

    format_date_less_one: (datetime) ->
      format_date_less_one(datetime)

    number_to_currency: (cents) ->
      number_to_currency({ cents: cents, symbol: @$store.getters['application/currency'] })

    build_default_qty: ->
      @qty = if !!@$store.state.cart.proxy_order_id
        @$store.getters['orders/offer_qty'](@offer.id) || 0
      else
        @$store.getters['cart/offer_qty'](@offer.id) || 0

    track_item_select: ->
      event_payload = @$store.getters['analytics/build_select_item_payload'](@offer)
      @$store.dispatch('analytics/select_item', event_payload)

    track_add_to_cart: ->
      event_payload = @$store.getters['analytics/build_select_item_payload'](@offer, @qty)
      @$store.dispatch('analytics/add_to_cart', event_payload)

    toggle_large_image: (show) ->
      return unless !!@offer && !!@offer.sellable.image_lg_url
      @enlarge_image = show
  },

  computed: {
    formatted_unit_price: ->
      return '' unless @offer

      text = "#{@$store.getters['application/currency']} #{@offer.price}"
      if @offer.per_kg then "#{text} /#{@offer.per_kg_symbol}" else text

    bulk_pack_savings: ->
      return null unless @offer
      cost_at_base_price = @offer.base_price_in_cents * Number(@offer.sellable.pack)
      savings_in_cents = cost_at_base_price - @offer.price_in_cents

      return @number_to_currency(savings_in_cents * @qty) if savings_in_cents > 0
      null

    offer: ->
      @$store.state.offers.current
    ,
    quantity_in_cart: ->
      @$store.getters['cart/offer_qty'](@offer?.id)
    ,
    unit_label: ->
      return 'kg' if @offer && @offer.per_kg
      ''
    ,
    add_to_cart_total: ->
      return '' unless @offer

      @number_to_currency(@qty * Number(@offer.price_in_cents))
  },

  watch: {
    offer: (offer) ->
      @enlarge_image = false
      if !!offer
        @build_default_qty()
        @track_item_select()
        @show = true
      else
        @show = false
  }
}
